import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { Store, Slices } from '@supersonic/store';
import { useStore as useZustandStore } from 'zustand';
import { useShallow } from 'zustand/react/shallow';

export const StoreContext = createContext<Store | null>(null);

export const useStore = <T,>(selector: (state: Slices) => T): T => {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing StoreContext.Provider in the tree');
  return useZustandStore(store, useShallow(selector));
};

export const StoreProviderAndInitializer = ({
  store,
  children,
}: {
  store: Store;
  children: ReactNode;
}) => {
  useEffect(() => {
    // Need cookies so should initialise only on client side
    store.getState().utmParams.init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
