import { StateCreator } from 'zustand';
import { Slices } from '../types';
import {
  EmailSubscriptionConfig,
  EmailSubscriptionSlice,
  EmailPreferences,
} from './types';
import { getCookie, setSessionCookie, removeCookie } from '../utils/cookies';
import { showAnchorAds, hideAnchorAds } from '../utils/anchorAds';

export const createEmailSubscriptionSlice =
  (
    config: EmailSubscriptionConfig | null,
  ): StateCreator<Slices, [], [], EmailSubscriptionSlice> =>
  (set, get) => ({
    config,
    channel: null,
    preferences: null,
    unsubscribeStatus: 'not_requested',
    updatePreferencesStatus: 'not_requested',
    isEmailSubscribed: false,
    emailModal: { visible: false },
    isEmailSubscriptionError: false,
    init: async (channel) => {
      const config = get().emailSubscription.config;
      if (!config) return;
      const isUserCameFromEmail = get().utmParams.source === 'email';
      if (isUserCameFromEmail) {
        setSessionCookie(config.isEmailSubscribedCookieName, '1');
      }
      const queryParams = new URLSearchParams(window.location.search);
      const disableEmailPopup = queryParams.get('intCrm') === 'false';
      if (disableEmailPopup) {
        setSessionCookie(config.isEmailPopupShownCookieName, '1');
      }

      const isEmailSubscribed = !!getCookie(config.isEmailSubscribedCookieName);
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          isEmailSubscribed,
          channel,
        },
      }));
    },
    showEmailModal: (trigger) => {
      hideAnchorAds();
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          emailModal: {
            visible: true,
            trigger,
          },
        },
      }));
    },
    hideEmailModal: () => {
      showAnchorAds();
      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          emailModal: { visible: false },
          isEmailSubscriptionError: false,
        },
      }));
    },
    subscribe: async (email, trigger) => {
      const config = get().emailSubscription.config;
      const channel = get().emailSubscription.channel;
      if (!config) return;

      try {
        const response = await fetch(
          `${config.emailSubscriptionApi}/subscribe`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email_address: email,
              source: `${channel || 'other'}: ${trigger}`,
            }),
          },
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            isEmailSubscribed: true,
          },
        }));
        setSessionCookie(config.isEmailSubscribedCookieName, '1');
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            isEmailSubscriptionError: true,
          },
        }));
      }
    },
    loadPreferences: async () => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
        },
      }));

      try {
        const queryParams = new URLSearchParams(window.location.search);
        const userId = queryParams.get('userid');
        const response = await fetch(
          `${config.emailSubscriptionApi}/preferences?userId=${userId}`,
        );
        if (!response.ok) throw response;
        const preferences: EmailPreferences = await response.json();
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            preferences,
          },
        }));
      } catch (e) {
        window.location.replace('/');
      }
    },
    updatePreferences: async (preferencesInput) => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          updatePreferencesStatus: 'loading',
        },
      }));

      try {
        const queryParams = new URLSearchParams(window.location.search);
        const userId = queryParams.get('userid');
        const response = await fetch(
          `${config.emailSubscriptionApi}/preferences?userId=${userId}`,
          {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(preferencesInput),
          },
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            updatePreferencesStatus: 'success',
          },
        }));
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            updatePreferencesStatus: 'error',
          },
        }));
      }
    },
    unsubscribe: async (email) => {
      const config = get().emailSubscription.config;
      if (!config) return;

      set((state) => ({
        ...state,
        emailSubscription: {
          ...state.emailSubscription,
          unsubscribeStatus: 'loading',
        },
      }));

      try {
        const response = await fetch(
          `${config.emailSubscriptionApi}/unsubscribe`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email_address: email,
            }),
          },
        );
        if (!response.ok) throw response;
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            unsubscribeStatus: 'success',
            isEmailSubscribed: false,
          },
        }));
        removeCookie(config.isEmailSubscribedCookieName);
      } catch (e) {
        set((state) => ({
          ...state,
          emailSubscription: {
            ...state.emailSubscription,
            unsubscribeStatus: 'error',
          },
        }));
      }
    },
  });
